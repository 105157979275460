import { Alert } from '@zenchef/ds-react'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'

import { Bookings } from '@/types/types'
import { useTranslation } from '@/utils/hooks'
import StoresContext from '@/utils/StoresContext'

const STRICTLY_NOT_MODIFIABLE = [
  'no_longer_time',
  'shift_feature_disabled',
  'unprocessable_phase'
] as const satisfies Bookings.UnmodifiableReasons[]

const UnmodifiableWarning = observer(() => {
  const { appStore } = useContext(StoresContext)
  const { booking } = appStore.state
  const { t } = useTranslation()

  if (!booking) return null

  const { unmodifiable_by_enduser_reasons } = booking

  if (unmodifiable_by_enduser_reasons.length === 0) return null

  return (
    <Alert reversed function='warning' width='100%'>
      {STRICTLY_NOT_MODIFIABLE.some((reason) => unmodifiable_by_enduser_reasons.includes(reason))
        ? t('ebm.warning.booking_not_modifiable')
        : t('ebm.warning.booking_not_modifiable_call')}
    </Alert>
  )
})

UnmodifiableWarning.displayName = 'UnmodifiableWarning'

export default UnmodifiableWarning
