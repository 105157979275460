import { Alert } from '@zenchef/ds-react'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'

import useSearchBookingWish from '@/components/redesign/filters/useSearchBookingWish'
import { useTranslation } from '@/utils/hooks'
import StoresContext from '@/utils/StoresContext'

const WarningsForSelectedFilters = observer(() => {
  const { appStore } = useContext(StoresContext)
  const { nextPageUrl } = useSearchBookingWish()

  const { isInUpdateFlow, shouldPrechargeOrPrepayShift, shouldPrecharge, wish } = appStore.state
  const { t } = useTranslation()

  const shouldDisplayPrepaymentWarning = isInUpdateFlow && shouldPrechargeOrPrepayShift

  if (shouldDisplayPrepaymentWarning) {
    return (
      <Alert reversed function='warning'>
        {shouldPrecharge ? t('ebm.warning.wish_requires_precharge') : t('ebm.warning.wish_requires_prepay')}
      </Alert>
    )
  }
  const shouldDisplayOfferSelectionClearedWarning = nextPageUrl === '/form' && wish.offers.length > 0

  if (shouldDisplayOfferSelectionClearedWarning) {
    return (
      <Alert reversed function='warning'>
        {t('ebm.warning.wish_will_flush_offers')}
      </Alert>
    )
  }

  return null
})

WarningsForSelectedFilters.displayName = 'WarningsForSelectedFilters'

export default WarningsForSelectedFilters
