import React, { useState } from 'react'

export type AccordionItemKey = string | string[] | undefined

interface AccordionBlockContextType {
  currentItem?: AccordionItemKey
  setCurrentItem: (newValue) => void
}
const AccordionBlockContext = React.createContext<AccordionBlockContextType>({} as AccordionBlockContextType)

type ControlledAccordionBlockProps = {
  currentItem: AccordionItemKey
  setCurrentItem: (newValue) => void
}

type UncontrolledAccordionBlockProps = {
  /**
   * 'defaultOpenedItemId': makes it possible to open a child accordion item on first render
   */
  defaultOpenedItemId?: string
}

type AccordionBlockProps = {
  children: React.ReactNode | ((ctx: AccordionBlockContextType) => React.ReactNode)
} & (ControlledAccordionBlockProps | UncontrolledAccordionBlockProps)

const AccordionBlock = ({ children, ...props }: AccordionBlockProps): JSX.Element => {
  const controlled = 'currentItem' in props
  const [currentItemInternal, setCurrentItemInternal] = useState(controlled ? undefined : props.defaultOpenedItemId)

  const currentItem = controlled ? props.currentItem : currentItemInternal
  const setCurrentItem = controlled ? props.setCurrentItem : setCurrentItemInternal

  return (
    <AccordionBlockContext.Provider
      value={{
        currentItem,
        setCurrentItem
      }}>
      <AccordionBlockContext.Consumer>
        {(ctx) => (typeof children === 'function' ? children(ctx) : children)}
      </AccordionBlockContext.Consumer>
    </AccordionBlockContext.Provider>
  )
}

const useAccordionBlockContext = () => React.useContext(AccordionBlockContext)

export default AccordionBlock
export { useAccordionBlockContext }
