import { formatToTimeZone } from '@zenchef/date-fns-timezone'
import { Alert } from '@zenchef/ds-react'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'

import { Button } from '@/components/redesign/common/Button'
import Hr from '@/components/redesign/common/Hr'
import SuggestedRestaurantsForCurrentDay from '@/components/redesign/filters/SuggestedRestaurantsForCurrentDay'
import RestaurantComment from '@/components/RestaurantComment'
import CalendarUtils from '@/components/ui/Calendar/CalendarIntl'
import getDateFnsLocal from '@/utils/getDateFnsLocal'
import { useTranslation } from '@/utils/hooks'
import useDayModifiers from '@/utils/hooks/useDayModifiers'
import StoresContext from '@/utils/StoresContext'

interface MoreInfoAboutDateProps {
  handleDaySelected: (day: string) => void
}

const MoreInfoAboutDate = observer(({ handleDaySelected }: MoreInfoAboutDateProps) => {
  const { appStore } = useContext(StoresContext)
  const { t } = useTranslation()
  const {
    isCurrentDayAvailableForCurrentPax,
    openDays,
    wish: { day, pax },
    isLoading,
    language,
    isCurrentDayOpen
  } = appStore.state
  const firstOpenDayString = openDays.length ? openDays[0] : undefined
  const firstOpenDay = firstOpenDayString ? new Date(firstOpenDayString) : undefined

  const currentDate = new Date(day)
  const firstOpenDayIsAfterCurrentMonth = firstOpenDay
    ? firstOpenDay.getMonth() !== currentDate.getMonth() && firstOpenDay > currentDate
    : false

  const locale = getDateFnsLocal(language)
  const { getDayTooltip, getDayActiveModifier } = useDayModifiers()

  if (isCurrentDayAvailableForCurrentPax) {
    return null
  }

  const activeModifier = getDayActiveModifier(currentDate)

  const formattedDate = formatToTimeZone(new Date(day), 'dddd D MMMM', {
    locale,
    timeZone: 'UTC'
  })

  // Conditions
  // 1. if isOpen (no waitlist) : no alert (early return above)
  // 2. if firstOpenDay is in the current month => no button, specific day alert
  // 3. if firstOpenDay is in the next month
  //       - fullPax => button, specific month alert
  //       - waitlistOpen => no button, specific day alert (iso 2.)
  //       - else => button, generic month alert
  // 4.if firstOpenDay is not in the 2 next months => button, generic no availability alert

  return (
    <>
      {!isLoading && (
        <Hr borderColor='border.neutral-on-brand.subtle' color='content.neutral.subtle' textStyle='mono.s'>
          {getDayTooltip(currentDate)}
        </Hr>
      )}

      {!isCurrentDayOpen && <RestaurantComment specific />}

      {openDays.length ? (
        firstOpenDayString && firstOpenDayIsAfterCurrentMonth && activeModifier !== 'waitlistOpen' ? (
          <>
            <Button
              testId='next-available-date'
              hierarchy='outlined'
              reversed
              size='normal'
              onClick={async () => {
                await appStore.selectWishDay(firstOpenDayString)
                handleDaySelected(firstOpenDayString)
              }}>
              {t('next_date_available')}
            </Button>
            <Alert
              reversed
              function='information'
              title={t(`${activeModifier === 'fullPax' ? 'fullPax' : 'no_availability'}.month.title`, {
                count: pax,
                month: firstOpenDay ? CalendarUtils.formatMonthTitle(firstOpenDay, language) : undefined
              })}
              description={t(`${activeModifier === 'fullPax' ? 'fullPax' : 'no_availability'}.month.description`)}
            />
          </>
        ) : (
          <Alert
            reversed
            function='information'
            title={t(`${activeModifier === 'past' ? 'no_availability' : activeModifier}.day.title`, {
              count: pax,
              date: formattedDate
            })}
            description={t(`${activeModifier === 'past' ? 'no_availability' : activeModifier}.day.description`)}
          />
        )
      ) : isLoading ? (
        <Alert reversed function='information'>
          {t('looking_for_availabilities')}
        </Alert>
      ) : (
        <Alert
          reversed
          function='information'
          title={t('no_availability.no_other.title', { count: pax })}
          description={t('no_availability.no_other.description')}
        />
      )}

      <SuggestedRestaurantsForCurrentDay />
    </>
  )
})

MoreInfoAboutDate.displayName = 'MoreInfoAboutDate'

export default MoreInfoAboutDate
