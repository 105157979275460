import { Text } from '@zenchef/ds-react'
import { Stack } from '@zenchef/styled-system/jsx'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'

import Subtitle from '@/components/redesign/common/Subtitle'
import UnmodifiableWarning from '@/components/redesign/ebm/UnmodifiableWarning'
import { useTranslation } from '@/utils/hooks'
import StoresContext from '@/utils/StoresContext'

const WelcomeBooking = observer(() => {
  const { appStore } = useContext(StoresContext)
  const { booking } = appStore.state
  const { t } = useTranslation()

  if (!booking) return null

  const { is_modifiable_by_enduser } = booking

  return (
    <Stack py='padding.2' px='padding.4' align='center' textAlign='center' color='content.neutral.bold'>
      <Text textStyle='title.m'>
        {t('welcome_back', {
          civility: booking.civility ? t(`${booking.civility}_short`) : '',
          lastname: booking.lastname
        })}
      </Text>
      {is_modifiable_by_enduser ? <Subtitle>{t('modify_your_booking')}</Subtitle> : <UnmodifiableWarning />}
    </Stack>
  )
})

export default WelcomeBooking
