import { observer } from 'mobx-react-lite'
import { useContext } from 'react'

import Hr from '@/components/redesign/common/Hr'
import Subtitle from '@/components/redesign/common/Subtitle'
import SuggestedRestaurantsSection from '@/components/redesign/suggestions/SuggestedRestaurantsSection'
import { useTranslation } from '@/utils/hooks'
import StoresContext from '@/utils/StoresContext'

const SuggestedRestaurantsForCurrentDay = observer(() => {
  const { appStore } = useContext(StoresContext)
  const { t } = useTranslation()
  const { isInUpdateFlow, isCurrentDayAvailableInSuggestedRestaurants } = appStore.state
  if (!isInUpdateFlow && isCurrentDayAvailableInSuggestedRestaurants) {
    return (
      <>
        <Hr
          textTransform='uppercase'
          textStyle='title.xs'
          borderColor='border.neutral-on-brand.subtle'
          color='content.neutral.subtle'>
          {t('or')}
        </Hr>
        <Subtitle textAlign='center'>{t('suggested_restaurants_page_title')}</Subtitle>
        <SuggestedRestaurantsSection />
      </>
    )
  }
})

SuggestedRestaurantsForCurrentDay.displayName = 'SuggestedRestaurantsForToday'

export default SuggestedRestaurantsForCurrentDay
