import { observer } from 'mobx-react-lite'
import { useContext } from 'react'

import { Button } from '@/components/redesign/common/Button'
import { WishFilter } from '@/types/types'
import { useTranslation } from '@/utils/hooks'
import StoresContext from '@/utils/StoresContext'

import useSearchBookingWish, { NextPageUrl } from './useSearchBookingWish'

const MAP_NEXTPAGE_WITH_LABEL_KEY: Record<NextPageUrl | 'default', string> = {
  default: 'search',
  '/offers': 'search',
  '/form': 'book_now'
}

interface SearchBookingWishButtonProps {
  currentAccordionItem: WishFilter | undefined
  setCurrentAccordionItem: (item) => void
}

const SearchBookingWishButton = observer(
  ({ setCurrentAccordionItem, currentAccordionItem }: SearchBookingWishButtonProps) => {
    const { t } = useTranslation()
    const { goToNextPage, nextPageUrl, isRoomMissing, isWaitlist, updateSelection, accordionItemToOpen } =
      useSearchBookingWish()
    const { appStore } = useContext(StoresContext)
    const { isInUpdateFlow, booking, shouldPrechargeOrPrepayShift } = appStore.state
    const labelKey = isWaitlist
      ? 'book_waiting_list'
      : isInUpdateFlow
        ? 'book_modify_booking'
        : MAP_NEXTPAGE_WITH_LABEL_KEY[nextPageUrl ?? 'default']

    const cannotProceedWithModification =
      isInUpdateFlow && (!booking?.is_modifiable_by_enduser || shouldPrechargeOrPrepayShift)
    const cannotProceedWithBooking = !nextPageUrl || isRoomMissing

    const disabled =
      cannotProceedWithModification ||
      (cannotProceedWithBooking && (accordionItemToOpen === undefined || accordionItemToOpen === currentAccordionItem))

    const handleClick = () => {
      if (cannotProceedWithBooking) {
        setCurrentAccordionItem(accordionItemToOpen)
      } else {
        updateSelection()
        goToNextPage()
      }
    }

    return (
      <Button
        hierarchy='primary'
        reversed
        size='normal'
        disabled={disabled}
        onClick={handleClick}
        testId={`${labelKey}`}>
        {t(labelKey)}
      </Button>
    )
  }
)

export default SearchBookingWishButton
