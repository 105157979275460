import { Stack } from '@zenchef/styled-system/jsx'

import SelectableItem from '@/components/redesign/common/SelectableItem'
import { Bookings } from '@/types/types'
import { useTranslation } from '@/utils/hooks'

interface AreaInputProps {
  isRequired: boolean
  options: Bookings.Room[]
  selectedValue: number | null
  onSelectValue: (area: { id: number | null; name: string }) => void
}

const AreaInput = ({ options, selectedValue, onSelectValue, isRequired }: AreaInputProps): JSX.Element => {
  const { t, translateField } = useTranslation()
  return (
    <Stack gap='gap.1'>
      {isRequired ? null : (
        <SelectableItem
          onClick={() => onSelectValue({ id: null, name: 'no_seating_preference' })}
          selected={selectedValue === null}
          testId='no-preference'>
          {t('no_seating_preference')}
        </SelectableItem>
      )}
      {options.map((room) => {
        return (
          <SelectableItem
            key={room.id}
            selected={room.id === selectedValue}
            testId={`room-${room.id}`}
            onClick={() => onSelectValue({ id: room.id, name: room.name })}>
            {translateField(room.name_translations)}
          </SelectableItem>
        )
      })}
    </Stack>
  )
}

export default AreaInput
