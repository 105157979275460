import { Alert } from '@zenchef/ds-react'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'

import useSlotSelector from '@/components/redesign/filters/SlotInput/useSlotSelector'
import SuggestedRestaurantsForCurrentDay from '@/components/redesign/filters/SuggestedRestaurantsForCurrentDay'
import { useTranslation } from '@/utils/hooks'
import StoresContext from '@/utils/StoresContext'

const NoAvailabilityForCurrentRoom = observer(() => {
  const { appStore } = useContext(StoresContext)
  const { t } = useTranslation()
  const { hasSlotsToSelect } = useSlotSelector()

  const { isCurrentDayAvailableForCurrentPax, wish, selectedRoomName } = appStore.state

  if (isCurrentDayAvailableForCurrentPax && !hasSlotsToSelect && wish.room_id !== null) {
    return (
      <>
        <Alert
          reversed
          function='warning'
          title={t('no_availability.seating_area.title', { seating_area: selectedRoomName })}
          description={t('no_availability.seating_area.description')}
        />
        <SuggestedRestaurantsForCurrentDay />
      </>
    )
  }
})

NoAvailabilityForCurrentRoom.displayName = 'NoAvailabilityForCurrentRoom'

export default NoAvailabilityForCurrentRoom
