import { sva } from '@zenchef/styled-system/css'

export const accordionItemRecipe = sva({
  slots: ['root', 'header', 'headerLeft', 'title', 'content'],
  base: {
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: 'gap.0',
      borderColor: 'border.neutral-on-brand.subtle',
      color: 'content.neutral.subtle',
      borderBottomWidth: 'm'
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      textStyle: 'title.m',
      margin: 'gap.1',
      height: 'xl',
      px: 'padding.3',
      borderRadius: 'l',
      _hover: {
        bg: 'background.neutral-on-brand.base.subtlest-hover'
      },
      _focusVisible: {
        outline: 'none',
        border: 'm',
        borderColor: 'border.neutral-on-brand.bolder',
        shadow: 'effect.focus-ring.neutral'
      },
      _aromaticonChildren: {
        fontSize: 'icon.base'
      }
    },
    headerLeft: {
      display: 'flex',
      columnGap: 'gap.2',
      alignItems: 'center',
      _aromaticonChildren: {
        fontSize: 'icon.base'
      }
    },
    content: {
      p: 'padding.4'
    }
  },
  variants: {
    hideBorder: {
      true: {
        root: {
          borderBottomWidth: '0px'
        }
      }
    },
    disabled: {
      true: {
        root: {
          color: 'content.neutral.disabled',
          borderColor: 'border.neutral-on-brand.subtlest'
        },
        title: {
          color: 'content.neutral.disabled'
        }
      }
    },
    filled: {
      true: {
        title: {
          color: 'content.neutral.bold'
        }
      }
    },
    opened: {
      true: {
        root: {
          bg: 'background.neutral-on-brand.base.subtlest'
        }
      }
    }
  }
})
