import { Icon } from '@zenchef/ds-react'
import { IconName } from '@zenchef/icomoon'
import React, { useEffect, useId } from 'react'

import { accordionItemRecipe } from '@/components/redesign/common/Accordion/AccordionItemRecipe'
import Collapsible from '@/components/redesign/common/Collapsible/Collapsible'
import getTestId from '@/utils/getTestId'

import { useAccordionBlockContext } from './AccordionBlock'

interface AccordionItemContextType {
  toggle: () => void
}

interface AccordionItemProps extends TestingAttributes {
  id: string
  title?: string
  placeholder?: string
  icon: IconName
  hideBorder?: boolean
  children?: React.ReactNode | ((ctx: AccordionItemContextType) => React.ReactNode)
  onOpen?: () => void
  scrollable?: boolean
  disabled?: boolean
  maxHeight?: string
  fixedHeight?: boolean
}

const AccordionItemContext = React.createContext<AccordionItemContextType>({} as AccordionItemContextType)

const AccordionItem = ({
  title,
  icon,
  children,
  placeholder,
  hideBorder,
  id: propsId,
  onOpen,
  scrollable,
  testId,
  disabled,
  maxHeight,
  fixedHeight
}: AccordionItemProps): JSX.Element => {
  const reactId = useId()
  const id = propsId ?? reactId
  const { currentItem, setCurrentItem } = useAccordionBlockContext()
  const isCollapsed = disabled || currentItem !== id
  const classes = accordionItemRecipe({
    hideBorder,
    filled: title !== null && title !== undefined,
    opened: !isCollapsed,
    disabled
  })

  const toggle = () => {
    if (isCollapsed) {
      setCurrentItem(id)
    } else {
      setCurrentItem(undefined)
    }
  }

  useEffect(() => {
    if (!isCollapsed) {
      onOpen?.()
    }
  }, [isCollapsed, onOpen])

  return (
    <AccordionItemContext.Provider value={{ toggle }}>
      <div className={classes.root}>
        <Collapsible isCollapsed={isCollapsed} toggle={toggle}>
          <Collapsible.Header
            className={classes.header}
            data-testid={getTestId(testId, 'accordion-item')}
            disabled={disabled}>
            <div className={classes.headerLeft}>
              <Icon name={icon} />
              <span className={classes.title}>{title ?? placeholder}</span>
            </div>
            <Icon name={isCollapsed ? 'chevron-down' : 'chevron-up'} />
          </Collapsible.Header>
          <Collapsible.Content
            scrollable={scrollable}
            className={classes.content}
            fixedHeight={fixedHeight}
            maxHeight={maxHeight}>
            <AccordionItemContext.Consumer>
              {(ctx) => (typeof children === 'function' ? children(ctx) : children)}
            </AccordionItemContext.Consumer>
          </Collapsible.Content>
        </Collapsible>
      </div>
    </AccordionItemContext.Provider>
  )
}

const useAccordionItemContext = () => React.useContext(AccordionItemContext)
export default AccordionItem
export { useAccordionItemContext }
